<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    no-close-on-backdrop
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="text-danger font-weight-bold">
      ВЫ ТОЧНО ХОТИТЕ УДАЛИТЬ ПОЛИС?<br>
      ВОССТАНОВИТЬ УДАЛЕННЫЙ ПОЛИС НЕВОЗМОЖНО !
    </div>

    <div class="mt-3 d-flex">
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        @click="deletePolicy"
      >
        Да
        <b-spinner
          v-if="isLoading"
          :disabled="isLoading"
          variant="light"
          small
        />
      </b-button>
      <b-button
        class="ml-2"
        :type="$const.PRIMARY_BUTTON"
        @click="onClose"
      >
        Отмена
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { api } from '@/helpers/api';
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'DeletePolicyModal',

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Полное удаление полиса',
    },
    policyId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async deletePolicy() {
      this.isLoading = true;

      try {
        const params = new URLSearchParams();
        params.append('id', this.policyId);
        const response = await api.delete('v2/policy', {
          params,
        });

        if (response.status === 200) {
          showCustomMessage('success', 'Полис успешно удален', '');
        }

        this.$store.commit(this.$types.TOGGLE_POLICY_SEARCH_TRIGGER);
        this.onClose();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
